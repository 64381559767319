<template>
  <div class="bg-white-gray px-5">
    <b-modal id="bv-modal-subscribe" 
      fade
      hide-footer 
      no-close-on-backdrop
      header-class="subscribe-header"
      dialog-class="subscribe-dialog"
      content-class="subscribe-content"
      body-class="subscribe-body"
      modal-class="subscribe-modal"
    >
      <template #modal-header="{ close }">
        <h3 style="font-weight: 600;">구독 솔루션 관리</h3>
        <b-button size="sm" variant="simple-circle" @click="close()">닫기</b-button>
      </template>

      <div>
        <div class="d-block mb-2">
          <h4 style="font-weight: 500;">나의 구독</h4>
        </div>
        <div class="df">
          <div class="df fs-12">
            <img class="" src="../../../assets/svg/icon-x-tools.svg" alt="xcost-tools-icon" width="50px" height="50px">
          </div>
  
          <div v-if="allLicense.length > 0" class="df col" style="flex-direction: column;">
            <div class="content-card mb-2" v-for="(license, index) in allLicense" :key="index">
              <div class="display-base">
                <h5 class="mb-1 mr-1">{{ license.prdName }}</h5>
                <b-badge v-if="endDtDiff(license.lvEndDt) > 0" variant="success">활성화</b-badge>
                <b-badge v-if="endDtDiff(license.lvEndDt) < 0" variant="danger">만료됨</b-badge>
                <b-badge v-if="license.payType === 'COUPON'" class="ml-1 fc-w" variant="warning">쿠폰</b-badge>
              </div>
  
              <p>사용가능 기간: {{ dateFormatter(license.lvEndDt) }} 만료</p>
              <p style="color: #d00000; font-size: 10pt;">유지보수 기간: {{ dateFormatter(license.maEndDt) }} 만료</p>
              <!-- <a href="#">> 기간연장</a> -->
            </div>
          </div>
          <div v-else class="df col ml-2" style="flex-direction: column;">
            <div class="display-base content-card mb-2">
              <img src="../../../assets/svg/icon-bulb.svg" alt="icon-bulb" width="50px" height="50px">
              <div class="ml-4">
                <h5 class="mb-1">구독 솔루션이 아직 없으세요?</h5>
                <p>어떤 솔루션을 사용해야할지 고민되시나요? 제품소개를 읽어보세요!</p>
                <p>가격이 부담되시나요? 원하는 기능만 선택해서 구독할 수 있어요!</p>
                <div class="">
                  <b-button class="mt-2" size="sm" variant="simple-circle" @click="locationIntroduction">제품소개</b-button>
                  <b-button class="mt-2 ml-2" size="sm" variant="simple-circle" @click="locationStore">스토어</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-subscribe')">Close Me</b-button> -->
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import { apiCall } from '@/common/utils';

export default {
  name: 'AccountManager',
  data() {
    return {
      userInfo: null,
      allLicense: [],
      today: moment().add(9, 'hours'),
    }
  },
  async created() {
    this.setUserInfo;

    const r = await apiCall('GET', `/user/auth/get-token`);
    if (r.result) {
      this.allLicenseFind();
    }

  },
  computed: {
    setUserInfo() {
      this.userInfo = store.state.userStore;
      // console.log("userInfo", this.userInfo);
    },
  },
  methods: {
    dateFormatter(date) { return moment(date).format('YYYY-MM-DD HH:mm'); },
    endDtDiff(lvEndDt) { return moment(lvEndDt).diff(this.today, 'minutes'); },
    locationIntroduction() {
      this.$bvModal.hide('bv-modal-subscribe');
      window.location = '#/introduction';
    },
    locationStore() {
      this.$bvModal.hide('bv-modal-subscribe');
      window.location = '#/store';
    },
    async allLicenseFind() {
      try {
        const r = await apiCall('POST', `/api/member/all-license`);
        // console.log(" /api/member/all-license ", r);
        if (r.code === 200) {
          this.allLicense = r.result;
        } else if (r.code === 10401) {
          console.log("This API service requires login");
        } else {
          throw new Error("allLicense join failed..");
        }
      } catch (error) {
        console.error("create promise error: ", error);
      }
    }
  },
}
</script>

<style>
p {
  margin: 0;
}
.content-card {
  background-color: rgba(255, 255, 255) !important;
  border: solid 1px #eaeaea;
  border-radius: 4px;
  padding: 1em 2em;
}

.subscribe-header {
  align-items: center;
  padding: 1rem 0 0.5rem 0 !important;
}
.subscribe-dialog {
  margin-top: 120px !important;
  max-width: 700px;
}
.subscribe-content {
  padding: 1rem 2rem !important;
  color: #000000;
}
.subscribe-body {
  padding: 2rem 0 !important;
}
.subscribe-modal {
  transition: opacity 0.3s ease-in-out;
}
</style>
