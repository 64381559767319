<template>
    <div>
      <Header/>
      <transition name="fade">
        <router-view class="content-main"></router-view>
      </transition>
      <Footer/>
    </div>
  </template>
  
  <script>
  import Header from '../MainLayout/header.vue';
  import Footer from '../MainLayout/footer.vue';
  
  export default {
    name: 'MainContainer',
    components: {
      Header,
      Footer
    },
    data(){
      return {
  
      }
    },
  }
  </script>
  
  <style>
  </style>
